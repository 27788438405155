import React, { Component } from "react";
import { Container, Row, Col } from "reactstrap";
import { Link } from "react-router-dom";
import apex from "../../assets/images/logo/apex.png";
import login_icon from "../../assets/images/icons/login_icon.png";
import Config from "../../configure";
import $ from "jquery";
import "./index.css";
class Header extends Component {
  constructor(props) {
    super(props);
    this.state = {
      navMenuMobile: false,
      redirect: false,
    };
  }
  toggleNavMenu = () => {
    this.setState({ navMenuMobile: !this.state.navMenuMobile });
  };
  handleScroll = () => {
    if (this.mount) {
      this.setState({ scroll: window.scrollY });
    }
  };

  componentDidMount() {
    this.mount = true;
    const el = document.querySelector(".gc_main_menu_wrapper");
    this.setState({ top: el.offsetTop + 700, height: el.offsetHeight });
    window.addEventListener("scroll", this.handleScroll);
  }
  componentDidUpdate() {
    this.state.scroll > this.state.top
      ? (document.body.style.paddingTop = `${this.state.height}px`)
      : (document.body.style.paddingTop = "0");
  }
  componentWillUnmount() {
    this.mount = false;
  }
  render() {
    $(document).ready(() => {
      $('.wd_single_index_menu ul li a[href^="#"]').bind(
        "click",
        function (event) {
          event.preventDefault();
          event.stopPropagation();
          var $anchor = $(this);
          if ($(window).width() > 991) {
            var headerH = "60";
          } else {
            headerH = "56";
          }
          $("html, body")
            .stop()
            .animate(
              {
                scrollTop:
                  $($anchor.attr("href")).offset().top - headerH + "px",
              },
              800
            );
        }
      );
      $(window).scroll(function () {
        var windscroll = $(window).scrollTop();
        var target = $(".wd_single_index_menu ul li");
        if (windscroll >= 0) {
          $(".wd_scroll").each(function (i) {
            if ($(this).position().top <= windscroll + 90) {
              target.removeClass("active");
              target.eq(i).addClass("active");
            }
          });
        } else {
          target.removeClass("active");
          $(".wd_single_index_menu ul li:first").addClass("active");
        }
      });
    });
    let navigation = (
      <ul>
        <li>
          <a
            className="nav-link"
            style={{ fontWeight: "bolder" }}
            href="#default"
          >
            Home
          </a>
        </li>
        <li>
          <a
            className="nav-link"
            style={{ fontWeight: "bolder" }}
            href="#offering"
          >
            Offering
          </a>
        </li>
        <li>
          <a
            className="nav-link"
            style={{ fontWeight: "bolder" }}
            href="#project"
          >
            Our Companies
          </a>
        </li>
        <li>
          <a
            className="nav-link"
            style={{ fontWeight: "bolder" }}
            href="#platforms"
          >
            Our Products
          </a>
        </li>
        <li>
          <a
            className="nav-link"
            style={{ fontWeight: "bolder" }}
            href="#brands"
          >
            Our Brands
          </a>
        </li>
        <li>
          <a
            className="nav-link"
            style={{ fontWeight: "bolder" }}
            href="#future"
          >
            Tokens
          </a>
        </li>
        {/* <li>
          <a className="nav-link" href="#news">
            Media
          </a>
        </li> */}
        <li>
          <a
            className="nav-link"
            style={{ fontWeight: "bolder" }}
            href="#about"
          >
            About Us
          </a>
        </li>
      </ul>
    );
    return (
      <div id="default" className="wd_scroll_wrap wd_scroll" >
        <header
          className={`gc_main_menu_wrapper ${
            this.state.scroll > this.state.top
              ? "menu_fixed animated fadeInDown"
              : ""
          }`}
        >
          <Container fluid >
            <Row className="nav_header_wrapper">
              <Col  className="col-xs-12 new-col"  sm={12} md={3} lg={3}>
                <div className="logo-area">
                  <Link to={Config.defaultPath}>
                    <img
                      src={apex}
                      style={{ height: "60px", marginLeft: "40px" }}
                      alt="logo"
                    />
                    <span className="company_name">Apex Defi Labs</span>
                  </Link>
                  <div className="menu-area" style={{ display: "flex" }}>
                    <div id="register" className="login-btn">
                      <Link
                        to="/account/login"
                        
                        className="primary-bttn bttn-warning"
                      >
                        <img src={login_icon} alt="" />
                        <span>Login</span>
                      </Link>
                    </div>
                    <div id="login" className="login-btn">
                      <Link
                        to="/account/register/investor"
                        
                        className="register-bttn bttn-primary"
                      >
                        <img src={login_icon} alt="" />
                        <span>Register</span>
                      </Link>
                    </div>
                  </div>
                </div>
              </Col>
              <Col lg={9} md={9} sm={12} className="col-xs-6 new-col navs_list">
                <div className="menu-area hidden-xs">
                  <nav className="wd_single_index_menu btc_main_menu">
                    {navigation}
                  </nav>
                  
                  <div className="login-btn animate__animated animate__bounceInDown animate__slower">
                    <Link
                      to="/account/login"
                      className="primary-bttn bttn-warning"
                    >
                      <img src={login_icon} alt="" />
                      <span>Login</span>
                    </Link>
                  </div>
                  <div className="login-btn animate__animated animate__bounceInDown animate__slower">
                    <Link
                       to="/account/register/investor"
                       className="register-bttn bttn-primary"
                    >
                      <img src={login_icon} alt="" />
                      <span>Register</span>
                    </Link>
                  </div>
                </div>
              </Col>
            </Row>
          </Container>
        </header>
      </div>
    );
  }
}

export default Header;
